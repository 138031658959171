<template>
  <b-modal id="takeAssessmentConfirmation" ref="takeAssessmentConfirmation">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <p class="pop-up-title">Confirmation</p>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>

    <template #default="{}">
      <div class="row col-12 page-content-regular">
        <p v-if="selectedItems.checkConfirmation && selectedItems.statusName === 'DeleteAction'">
          <span class="page-heading-tab">Do you want to proceed on deleting this action?</span>
          <br /><br />
          <!-- <span class="deleteActionStyle">Action ID: </span> 
          <span class="deleteActionStyleDetails">{{ selectedItems.actionid }}</span>  <br /> -->
          <span class="deleteActionStyle">Action #: </span>
          <span class="deleteActionStyleDetails">{{ selectedItems.actionNumber }}</span> <br />
          <span class="deleteActionStyle">Description: </span>
          <span class="deleteActionStyleDetails">{{ selectedItems.actionDesc }}</span> <br />
        </p>
        <p v-else>
          <span class="page-heading-tab">You are marking status as '{{ selectedItems.statusName }}' without any comments
            or attachments.</span>
          <br /><br />
          If you wish to add a comment, please click
          <span class="page-heading-tab">"Discard"</span> button - then please add a comment and click on <span
            class="page-heading-tab">"Save"</span> button<br />
          Else you can click on
          <span class="page-heading-tab">"Proceed"</span> button to mark status as '{{ selectedItems.statusName }}'
          without any comments or attachments.
        </p>

        <p v-if="selectedItems.checkConfirmation && selectedItems.statusName === 'DeleteAction'">
          <span class="page-heading-tab">Do you want to proceed on deleting this action?</span>
          <br /><br />
          <span class="deleteActionStyle">Action #: </span>
          <span class="deleteActionStyleDetails">{{ selectedItems.actionNumber }}</span> <br />
          <span class="deleteActionStyle">Description: </span>
          <span class="deleteActionStyleDetails">{{ selectedItems.actionDesc }}</span> <br />
        </p>
        <p v-else-if="selectedItems.checkConfirmation && selectedItems.statusName === 'Not Started'">
          <span class="page-heading-tab">You are marking status as '{{ selectedItems.statusName }}'.</span>
          <br /><br />
          If you wish to cancel this action, please click
          <span class="page-heading-tab">"Discard"</span> button <br />
          Otherwise you can click on
          <span class="page-heading-tab">"Proceed"</span> button to mark status as '{{ selectedItems.statusName }}'.
        </p>
        <p v-else>
          <span class="page-heading-tab">You are marking status as '{{ selectedItems.statusName }}' without any comments
            or attachments.</span>
          <br /><br />
          If you wish to add a comment, please click
          <span class="page-heading-tab">"Discard"</span> button - then please add a comment and click on <span
            class="page-heading-tab">"Save"</span> button<br />
          Else you can click on
          <span class="page-heading-tab">"Proceed"</span> button to mark status as '{{ selectedItems.statusName }}'
          without any comments or attachments.
        </p>
        <p v-if="!selectedItems.checkConfirmation">
          <span class="page-heading-tab">You are marking status as '{{ selectedItems.statusName }}' with comments or
            attachments.</span>
          <br /><br />
          If you wish to edit the comment or reattach attachment, please click
          <span class="page-heading-tab">"Discard"</span> button - then please edit the comment and click on <span
            class="page-heading-tab">"Save"</span> button<br />
          Else you can click on
          <span class="page-heading-tab">"Proceed"</span> button to mark status as '{{ selectedItems.statusName }}' with
          existing comments or attachments.
        </p>
      </div>
      <div v-if="currentDay < 6 && selectedItems.statusName === 'Completed'">
        <strong><span class="page-heading-tab">Please select completion date</span></strong>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <div style="width: 45%">
            <b-input-group>
              <b-form-input id="completion-date-input" v-model="completionDateInput" type="text"
                placeholder="Completion Date" autocomplete="off" class="input-target-completion-date"
                disabled></b-form-input>
              <b-input-group-append>
                <b-form-datepicker id="completion-date-input-setting" v-model="completionDate" button-only right
                  locale="en-US" aria-controls="completion-date-input" dark label-no-date-selected="Completion Date"
                  :min="minDate" :max="now" :date-format-options="{
                      month: '2-digit',
                      day: '2-digit',
                      year: 'numeric'
                    }" @context="contextChange($event)">
                </b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button @click="closeModal(close)" pill variant="outline-secondary"
        class="page-heading-tab btn start-course-bt ed-st-btn btn-secondary rounded-pill" style="
          color: #274ba7;
          border-color: transparent;
          background-color: transparent;
          margin-top: 0.5em;
          width: 8.333333333333334rem;
          height: 2.9166666666666665rem;
          margin-right: 0.5rem;
        ">Discard</b-button>
      <b-button @click="proceed()" pill variant="outline-secondary"
        class="page-heading-tab btn start-course-bt ed-st-btn btn-secondary rounded-pill" :disabled="isButtonDisabled"
        style="border-color: #274ba7; background-color: #274ba7; color: #fff; margin-top: 0.5em; width: 8.333333333333334rem; height: 2.9166666666666665rem">Proceed</b-button>
    </template>
  </b-modal>
</template>
<script>
import ApiCalls from "../../../common/api/apiCalls";
import Api from "../../../common/constants/api";
import moment from "moment";
export default {
  name: "ConfirmationPopup",
  props: {
    selectedItems: {},
    closeApproval: Function
  },
  data() {
    return {
      isButtonDisabled: false,
      now: new Date(),
      minDate: new Date(),
      currentDay: new Date().getDate(),
      completionDate: moment(String(new Date())).format("YYYY-MM-DD"),
      completionDateInput: moment(String(new Date())).format("DD-MMM-YYYY")
    };
  },
  mounted() {
    this.minDate = new Date(this.now.getFullYear(), this.now.getMonth() - 1, 1);
    // console.log("minDate",this.minDate)
  },
  methods: {
    contextChange(e) {
      // console.log(e.selectedYMD)
      this.completionDateInput = moment(String(e.selectedYMD)).format("DD-MMM-YYYY");
      this.completionDate = e.selectedYMD;
    },
    closeModal(close) {
      this.completionDateInput = moment(String(new Date())).format("DD-MMM-YYYY");
      this.completionDate = moment(String(new Date())).format("YYYY-MM-DD");
      close();
    },
    proceed() {
      this.isButtonDisabled = true;
      let req = this.selectedItems;
      if (this.selectedItems.statusName === "Not Approved" || this.selectedItems.statusName === "Approved") {
        ApiCalls.httpCallDetails(Api.TAKEASSESSMENTSECONDPARTYAPPROVAL, "post", req).then(() => {
          // if (this.selectedItems.statusName === "Not Approved") {
          //   req.statusName = "Not Sustained";
          //   ApiCalls.httpCallDetails(
          //     Api.TAKEASSESSMENTSELFASSESSMENT,
          //     "post",
          //     req
          //   ).then(() => {
          this.closeApproval("second");
          this.$refs.takeAssessmentConfirmation.hide();
          //     //setTimeout(() => this.$router.go(), 500);
          //   });
          // // } else {
          //   this.closeApproval('second');
          //   this.$refs.takeAssessmentConfirmation.hide();
          //   // setTimeout(() => this.$router.go(), 500);
          // }
          this.isButtonDisabled = false;
        });
      } else if (this.selectedItems.statusName === "Not Started") {
        ApiCalls.httpCallDetails(Api.TAKEASSESSMENTSELFASSESSMENT, "post", req).then(() => {
          this.closeApproval("plant");
          this.$refs.takeAssessmentConfirmation.hide();
        });
      } else if (this.selectedItems.statusName === "DeleteAction") {
        ApiCalls.httpCallDetails(Api.DELETEACTION, "post", req).then(() => {
          alert("Action has been deleted successfully.");
          this.closeApproval("plant");
          this.$refs.takeAssessmentConfirmation.hide();
        });
      } else {
        if (this.completionDate) {
          let dt = new Date(this.completionDate).toLocaleString("en-US", {
            timeZone: "UTC"
          });
          req["updatedTimestamp"] = moment(dt).format("YYYY-MM-DDTHH:MM:SS");
        }

        ApiCalls.httpCallDetails(Api.TAKEASSESSMENTSELFASSESSMENT, "post", req).then(() => {
          this.closeApproval("plant");
          this.$refs.takeAssessmentConfirmation.hide();
          this.isButtonDisabled = false;
        });
      }
    }
  }
};
</script>
<style scoped>
.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.edit-btn {
  height: 2.2rem;
  padding-top: 0.25rem !important;
  margin-left: 0.5rem;
}

.deleteActionStyle {
  font-size: 1rem;
  font-weight: bold;
}

.deleteActionStyleDetails {
  font-size: 1rem;
}

.edit-btn:focus {
  background-color: #274ba7;
}
</style>
