<template>
  <b-modal id="viewHistoryItemDetails" ref="viewHistoryItemDetails">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <p class="pop-up-title">Comments</p>
      <b-button size="sm" class="close-modal" @click="close()"> x </b-button>
    </template>

    <template #default="{}">
      <div class="row col-12">
        <div class="col-7 page-heading-tab">Updated By</div>
        <div class="col-5 page-heading-tab">Date & Time</div>
      </div>
      <div class="row col-12">
        <div class="col-7 page-content-regular" style="word-wrap: break-word">
          {{ selectedItems.updatedBy }}
        </div>
        <div class="col-5 page-content-regular" style="word-wrap: break-word">
          {{ selectedItems.dateAndTime }}, {{ selectedItems.Time }}
        </div>
      </div>
      <div class="row col-12" style="margin-top: 3%">
        <div class="col-12 page-heading-tab">Comment</div>
        <div class="col-12 page-content-regular">
          {{ selectedItems.comment }}
        </div>
      </div>
      <div class="row col-12" style="margin-top: 3%" v-if="selectedItems.attachments.length > 0">
        <div class="col-12 page-heading-tab">Attachments</div>
        <div class="col-12">
          <ul class="uploaded-items">
            <li class="uploaded-items-li" v-for="documentDetail in selectedItems.attachments"
              :key="documentDetail.documentId">
              <span class="uploaded-file-name">
                <a class="li-doc-class" @click="downloadItem(documentDetail)" :download="documentDetail.documentName">{{
                  documentDetail.documentName.split('_$^#^$_')[0] +
                    documentDetail.documentName.split('_$^#^$_')[documentDetail.documentName.split('_$^#^$_').length - 1]
                }}</a>
              </span>
              <img alt="more" class="attch-icon" src="../../../assets/download 2.svg" />
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template #modal-footer="{ close }">
      <b-button @click="close()" pill class="primar-btn edit-btn button-small" size="lg">
        OK
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import axios from 'axios';
export default {
  name: "ViewHistoryItemDetails",
  props: {
    selectedItems: {},
  },
  data() {
    return {};
  },
  methods: {
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj.documentUrl));
      const label = JSON.parse(JSON.stringify(obj.documentName));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })

    },
  },
};
</script>
<style scoped>
.pop-up-title {
  color: #234372;
  font-size: 1.3333333333333333rem;
  font-family: "BarlowSb", sans-serif;
}

.uploaded-items {
  list-style: none;
  padding: 0;
}

.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.close-modal {
  background-color: transparent;
  border-color: transparent;
  color: #a3a3a3;
  font-size: 1.5rem;
  line-height: 0;
  padding: 0;
}

.edit-btn {
  height: 2.2rem;
  padding-top: 0.25rem !important;
  margin-left: 0.5rem;
}

.edit-btn:focus {
  background-color: #274ba7;
}

.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}
</style>
